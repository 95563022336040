import * as React from 'react';
import { Link } from 'gatsby';
import { Box, Container, Stack, Flex, SimpleGrid } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../components/seo';
import Layout from '../components/layout';
import CustomButton from '../components/custom-button';
import '../styles/technology.scss';

const Technology = () => (
  <Layout>
    <SEO
      title="Nutrient Recovery Solutions for Phosphorus and Magnesium in Waterways | Ostara"
      description="Learn how our nutrient recovery technology reclaims excess phosphorus and nitrogen from municipal and industrial water supplies to create a sustainable fertilizer."
      image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
      keywords="Nutrient Recovery Technology"
    />
    <main>
      <Box w="100%" p={4} className="product-hero-container">
        <Container maxW={1400}>
          <Box
            display="flex"
            flexDirection={['column', 'column', 'column', 'row']}
            justifyContent="space-between"
            paddingTop={[0, 0, 10, 50]}
          >
            <Box w={['100%', '100%', '100%', '46.5%']}>
              <Stack marginTop={[0, 0, 20, 20]} maxW="50rem" spacing={6}>
                <h1>Nutrient Recovery Solutions</h1>
                <p>
                  Our nutrient recovery solutions reclaim phosphorus and
                  nitrogen from waste waters to both improve water quality and
                  produce a sustainable fertilizer, Crystal Green Pearl
                  <sup>®</sup>.
                </p>
                <div className="button-wrappers">
                  <CustomButton maxW={230} to="/product/crystal-green">
                    CRYSTAL GREEN
                  </CustomButton>
                </div>
              </Stack>
            </Box>
            <Stack
              spacing={[4, 6, 4, 6, 8]}
              w={['100%', '100%', '100%', '46.5%']}
            >
              <Box className="benefits-hero-img-container">
                <StaticImage
                  className="hero-img"
                  placeholder="transparent"
                  src="../images/technology-hero-img-min.jpeg"
                  alt="Ostara hero img"
                />
              </Box>
            </Stack>
          </Box>
        </Container>
      </Box>
      <Box w="100%" p={4} className="technology-body-container">
        <Container className="technology-content-1--container" maxW={1400}>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={20}>
            <Box w="100%">
              <StaticImage
                placeholder="transparent"
                src="../images/technology-img1.png"
                alt="Ostara img"
              />
            </Box>

            <Flex direction="column" justifyContent="center" w="100%">
              <div className="technology-content-1--wrapper">
                <h3>The Challenge with Nutrients</h3>
                <p>
                  When nutrients aren’t managed properly and enter local water
                  ways they can feed algae blooms that harm the environment and
                  upset communities.
                </p>
              </div>
            </Flex>
          </SimpleGrid>

          <SimpleGrid marginTop={20} columns={{ sm: 1, md: 2 }} spacing={20}>
            <Flex direction="column" justifyContent="center" w="100%">
              <div className="technology-content-2--wrapper">
                <h3>Turning Nutrient Challenges into Opportunities</h3>
                <p>
                  We view the nutrients in wastewater as a valuable source of
                  renewable phosphorus and nitrogen that are just waiting to be
                  beneficially recovered. That’s why we&apos;ve developed our
                  nutrient recovery solutions to sustainably recover these
                  nutrients for use as high-value granular fertilizers while
                  also removing the nutrients from where we don’t want them -
                  water discharges.
                </p>
              </div>
            </Flex>
            <Box w="100%">
              <StaticImage
                placeholder="transparent"
                src="../images/technology-img2.png"
                alt="Ostara img"
              />
            </Box>
          </SimpleGrid>
        </Container>
        <Container marginTop={100} className="key-top-2" maxW={1400} />
        <Container className="tech-key-features" maxW={1400}>
          <SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} spacing={6}>
            <Flex className="tech-key-features--container">
              <StaticImage
                className="tech-key-features--icon"
                placeholder="transparent"
                src="../images/tech-icon1.svg"
                alt="Ostara logo"
              />
              <p>
                Efficiently recover
                <br />
                renewable phosphorus
              </p>
            </Flex>
            <Flex className="tech-key-features--container">
              <StaticImage
                className="tech-key-features--icon"
                placeholder="transparent"
                src="../images/home-icon2.svg"
                alt="Ostara logo"
              />
              <p>Conserve and protect water resources</p>
            </Flex>

            <Flex className="tech-key-features--container">
              <StaticImage
                className="tech-key-features--icon"
                placeholder="transparent"
                src="../images/home-icon3.svg"
                alt="Ostara logo"
              />
              <p>Cost-effectively solve operational challenges</p>
            </Flex>

            <Flex className="tech-key-features--container">
              <StaticImage
                className="tech-key-features--icon"
                placeholder="transparent"
                src="../images/home-icon4.svg"
                alt="Ostara logo"
              />
              <p>Create a revenue stream</p>
            </Flex>
          </SimpleGrid>
          <h2 className="technology-content--h2">
            How Ostara’s Nutrient Recovery Solutions Work
          </h2>
        </Container>
        <Container maxW={1400}>
          <hr className="custom-divider" />
        </Container>
        <Container maxW={1400} className="technology-content-3--container">
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={24}>
            <Flex direction="column" justifyContent="center">
              <div className="technology-content--wrapper">
                <h3>
                  PEARL<sup>®</sup>
                </h3>
                <h4>TRANSFORMS NUTRIENTS INTO FERTILIZER</h4>
                <p>
                  The core of Ostara’s comprehensive nutrient management
                  solution is the Pearl system, a fluid bed reactor designed for
                  efficient removal of phosphorus and recovery of a high-purity
                  fertilizer product.
                </p>
                <h4>HOW IT WORKS:</h4>
                <ul>
                  <li>
                    Nutrients crystallize into highly pure fertilizer granules,
                    in a controlled pH environment.
                  </li>
                  <li>Granules are harvested, dried and shipped offsite.</li>
                  <li>
                    Fertilizer is marketed and distributed as Crystal Green
                    Pearl<sup>®</sup>.
                  </li>
                </ul>
              </div>
            </Flex>
            <div>
              <StaticImage
                placeholder="transparent"
                src="../images/pearl.png"
                alt="Ostara icons"
              />
            </div>
          </SimpleGrid>
        </Container>
        <Container maxW={1400}>
          <hr className="custom-divider" />
        </Container>

        <Container maxW={1400} className="technology-content-3--container">
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={24}>
            <div>
              <StaticImage
                placeholder="transparent"
                src="../images/technology-img3-min.png"
                alt="Ostara icons"
              />
            </div>
            <Flex direction="column" justifyContent="center">
              <div className="technology-content--wrapper">
                <h3>WASSTRIP</h3>
                <h4>SUPPORTS FACILITIES USING ANAEROBIC DIGESTION</h4>
                <p>
                  WASSTRIP (Waste Activated Sludge Stripping to Remove Internal
                  Phosphorus) is a value-add option for Pearl systems, providing
                  critical benefits to facilities operating anaerobic digestion.
                </p>
                <h4>HOW IT WORKS:</h4>
                <ul>
                  <li>
                    Phosphorus is released and bypassed upstream <u>before</u>{' '}
                    entering the digester.
                  </li>
                  <li>
                    Nutrient recovery is turbocharged, increasing recovered
                    fertilizer production by controlling struvite in the Pearl
                    reactor and not in the digesters.
                  </li>
                  <li>
                    Digesters and equipment are protected from unwanted struvite
                    formation.
                  </li>
                  <li>
                    Dewaterability is improved and biosolids volume is reduced.
                  </li>
                </ul>
              </div>
            </Flex>
          </SimpleGrid>
        </Container>
        <Container maxW={1400}>
          <hr className="custom-divider" />
        </Container>
        <Container maxW={1400} marginTop={100} marginBottom={100}>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={24}>
            <Flex direction="column" justifyContent="center">
              <div className="technology-content-3--wrapper">
                <h3>23</h3>
                <h3>Commercial installations worldwide</h3>
              </div>
            </Flex>
            <div>
              <StaticImage
                placeholder="transparent"
                src="../images/technology-img4-min.png"
                alt="Ostara icons"
              />
            </div>
          </SimpleGrid>
        </Container>
      </Box>

      <Box w="100%" p={4} className="technology-bottom-hero--container1">
        <div className="overlay2" />
        <Container maxW={1400} className="technology-bottom-hero--content1">
          <Stack
            paddingTop={[16, 150]}
            paddingBottom={[16, 150]}
            maxW="40rem"
            spacing={6}
          >
            <h3>Discover Our Sustainable Fertilizer</h3>
            <p>
              Our Crystal Green Pearl fertilizer consists of phosphorus,
              nitrogen and magnesium reclaimed from wastewater systems, helping
              to solve nutrient management challenges.
            </p>
            <CustomButton maxW={190} to="/product/crystal-green">
              VIEW PRODUCT
            </CustomButton>
          </Stack>
        </Container>
      </Box>

      <Box w="100%" p={4} className="phosphorus-data-container">
        <Container maxW={1400}>
          <SimpleGrid
            columns={{ md: 1, lg: 2 }}
            className="phosphorus-data-info"
            spacing={6}
          >
            <StaticImage
              placeholder="transparent"
              src="../images/map-na-cropped.jpg"
              alt="Ostara icons"
            />
            <Box className="phosphorus-data-content">
              <h3>
                Learn How Global Communities Benefit from Nutrient Recovery
                Solutions
              </h3>
              <p>
                Recover nutrients in your wastewater treatment to convert
                phosphorus into sustainable, high-efficiency fertilizer, while
                also reducing operational costs and improving your environmental
                footprint.
              </p>
              <CustomButton maxW={230} to="/municipalities">
                LEARN MORE
              </CustomButton>
            </Box>
          </SimpleGrid>
        </Container>
      </Box>

      <Box w="100%" p={4} className="technology-bottom-hero--container2">
        <div className="overlay" />
        <Container maxW={1400} className="technology-bottom-hero--content2">
          <Stack
            paddingTop={[16, 150]}
            paddingBottom={[16, 150]}
            maxW="35rem"
            spacing={6}
          >
            <h2>Join the Growing Nutrient Recovery Movement</h2>
            <p>
              When using Ostara solutions to recover a valuable resource, you
              partner with your community and farmers to support our collective
              future. The phosphorus we recover into sustainable Crystal Green
              Pearl fertilizer provides a guaranteed revenue stream while
              improving your environmental footprint.
            </p>
            <CustomButton maxW={180} to="/contact">
              CONTACT US
            </CustomButton>
          </Stack>
        </Container>
      </Box>
      <Box w="100%" p={4} className="technology-body-container3">
        <Container maxW={1400}>
          <h2 className="technology-content--h2">Featured News</h2>
          <SimpleGrid marginTop={20} columns={{ md: 1, lg: 2 }} spacing={12}>
            <div>
              <StaticImage
                placeholder="transparent"
                src="../images/technology-img6-min.png"
                alt="Ostara img"
              />
            </div>
            <Flex
              direction="column"
              justifyContent="center"
              className="technology-body-content-3"
            >
              <h3>
                Evoqua Water Technologies Partners with Ostara to Expand Access
                to Nutrient Recovery Systems
              </h3>
              <p>
                In September 2021, Ostara and Evoqua announced a new partnership
                to share nutrient recovery technology through Evoqua’s
                marketing, sales, and project delivery resources. In this
                partnership with Ostara, Evoqua gains access to the most
                established technologies in the rapidly growing nutrient
                recovery market, while also enhancing many of the established
                Evoqua technologies that complement Ostara solutions.
              </p>
              <br />
              <p>
                Through this license agreement, Evoqua will sell and deliver
                nutrient management systems using Ostara’s Pearl<sup>®</sup> and
                WASSTRIP<sup>®</sup>
                wastewater treatment technologies across North America and
                Europe.
              </p>
              <br />
              <p>
                Explore how Ostara and Evoqua will be implementing this
                technology in a community near you.
              </p>
              <Link to="/media/evoqua-water-technologies-enters-into-partnership-with-ostara-nutrient-recovery-technologies-inc">
                Read more
              </Link>
            </Flex>
          </SimpleGrid>
        </Container>
        <Container maxW={1400}>
          <hr className="custom-divider" />
        </Container>
        {/* <Container
          className="product-container"
          maxW={1400}
          marginTop={100}
        >
          <h2 className="related-news-heading">Related News</h2>
          <Box className="related-news-container">
            <Flex flexDirection="column" className="related-news">
              <StaticImage
                placeholder="transparent"
                src="../images/pearl1.png"
                alt="Ostara img"
                layout="fixed"
                height={300}
                width={300}
              />

              <h5>Headline Here in 2 lines</h5>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor.
              </p>
              <Link to="/contact">Read more</Link>
            </Flex>
            <Flex flexDirection="column" className="related-news">
              <StaticImage
                placeholder="transparent"
                src="../images/pearl2.png"
                alt="Ostara img"
                layout="fixed"
                height={300}
                width={300}
              />

              <h5>Headline Here in 2 lines</h5>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor.
              </p>
              <Link to="/contact">Read more</Link>
            </Flex>
            <Flex flexDirection="column" className="related-news">
              <StaticImage
                placeholder="transparent"
                src="../images/pearl1.png"
                alt="Ostara img"
                layout="fixed"
                height={300}
                width={300}
              />

              <h5>Headline Here in 2 lines</h5>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor.
              </p>
              <Link to="/contact">Read more</Link>
            </Flex>
          </Box>
        </Container> */}
      </Box>
    </main>
  </Layout>
);

export default Technology;
